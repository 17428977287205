var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('transition',{attrs:{"name":"hidein"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenu),expression:"mobileMenu"}],staticClass:"mobile-menu",class:{
                'mobile-menu--login': _vm.phone,
                'mobile-menu--active': _vm.showMenuBar
            }},[_c('div',{staticClass:"mobile-menu__bar"},[_c('div',[_c('div',{staticClass:"mobile-menu__top"},[_c('a',{staticClass:"mobile-menu__logo",attrs:{"href":_vm.mobileMenuLink}}),_c('span',{staticClass:"mobile-menu__close-btn",on:{"click":_vm.toggleMenu}})]),(!_vm.showLogout)?_c('div',{staticClass:"mobile-menu__user-block"},[_vm._v(" Вход в личный кабинет ")]):_vm._e(),_c('div',[(_vm.menuList.length > 0)?_c('ul',{staticClass:"mobile-menu__list"},[_vm._l((_vm.menuList),function(item,index){return _c('li',{key:index},[(item.active)?_c('span',{staticClass:"mobile-menu__item mobile-menu__item-active"},[_vm._v(_vm._s(item.title))]):(item.path)?_c('router-link',{staticClass:"mobile-menu__item",class:{
                                                'mobile-menu__item-inactive': item.hide
                                            },attrs:{"to":item.path,"exact-active-class":"mobile-menu__item-active"}},[_vm._v(_vm._s(item.title))]):_c('a',{staticClass:"mobile-menu__item",attrs:{"href":item.link}},[_vm._v(_vm._s(item.title))])],1)}),(_vm.showLogout)?_c('li',[_c('span',{staticClass:"mobile-menu__item",on:{"click":_vm.logout}},[_c('span',{staticClass:"mobile-menu__item-icon"},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.09 12.59L8.5 14L13.5 9L8.5 4L7.09 5.41L9.67 8H0V10H9.67L7.09 12.59ZM16 0H2C0.89 0 0 0.9 0 2V6H2V2H16V16H2V12H0V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z","fill":"#73869D"}})])]),_vm._v(" Выйти ")])]):_vm._e()],2):_vm._e(),(_vm.mainMenuList.length > 0)?_c('ul',{staticClass:"mobile-menu__list"},_vm._l((_vm.mainMenuList),function(item,index){return _c('li',{key:index},[(item.active)?_c('span',{staticClass:"mobile-menu__item mobile-menu__item-active"},[_vm._v(_vm._s(item.title))]):(item.path)?_c('router-link',{staticClass:"mobile-menu__item",attrs:{"to":item.path,"active-class":"mobile-menu__item-active"}},[_vm._v(_vm._s(item.title))]):_c('a',{staticClass:"mobile-menu__item",attrs:{"href":item.link}},[_vm._v(_vm._s(item.title))])],1)}),0):_vm._e()])])]),_c('div',{staticClass:"mobile-menu__close",on:{"click":_vm.toggleMenu}})])]),_c('header',{staticClass:"header"},[_c('div',{staticClass:"header__container",class:{'header__container-repeater': _vm.isRepeater}},[_c('a',{staticClass:"header__logo",attrs:{"href":_vm.headerLogoLink}}),(_vm.showMenu)?_c('ul',{staticClass:"header__menu"},_vm._l((_vm.headerMenu),function(item,index){return _c('li',{key:index,class:{
                        'header__menu-inactive': item.hide
                    }},[(item.active)?_c('span',{staticClass:"header__menu-active"},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('a',{attrs:{"href":item.link}},[_vm._v(_vm._s(item.title))])])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRepeater),expression:"isRepeater"}],staticClass:"header__user-info"},[(!_vm.showLogout)?_c('a',{staticClass:"header__phone",attrs:{"href":"tel:+78007007286"}},[_vm._v("8 800 700-72-86")]):(_vm.isRepeater && _vm.showMenu)?_c('p',{staticClass:"header__username"},[_c('a',{attrs:{"href":_vm.personalAreaLink}},[_vm._v("Личный кабинет")])]):_vm._e(),(_vm.showLogout)?_c('button',{staticClass:"header__action",attrs:{"type":"button"},on:{"click":_vm.logout}},[_vm._v(" Выйти ")]):_c('button',{staticClass:"header__action",attrs:{"type":"button"},on:{"click":_vm.login}},[_vm._v(" Войти ")])]),(_vm.showMenu)?_c('div',{staticClass:"header__hamburger",on:{"click":_vm.toggleMenu}}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }