import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            captchaMode: false,
            captcha: '',
            captchaImage: '',
            captchaMessage: '',
        }
    },
    methods: {
        enableCaptchaMode: function (e): void {
            this.captchaMode = true;
            this.captchaImage = e.data.captcha
        },
        setCaptchaMessage: function (e): void {
            this.captchaMessage = e.message;
        },
        disableCaptchaMode: function (): void {
            this.captchaMode = false;
        },
    }
})
