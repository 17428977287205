<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        agreement: {
            type: Object,
            default: () => ({})
        },
        isChildren: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        value: {
            get() {
                return this.$store.getters['agreementsModel/agreementsMap'][this.agreement.code];
            },
            set(value) {
                this.updateAgreementsMap(value);
            }
        }
    },
    methods: {
        updateAgreementsMap(value) {
            this.$emit('change', {
                code: this.agreement.code,
                value
            });
            this.$store.dispatch('agreementsModel/updateAgreementsMap', {
                [this.agreement.code]: value
            });
        }
    }
});
</script>
<template>
<div class="radio" :class="{ 'radio--inline': isChildren }">
    <label class="radio__wrapper">
        <span class="radio__element">
            <input
                class="radio__input"
                v-model="value"
                :name="agreement.group"
                type="radio"
                :value="agreement.value"/>
            <!--<span class="radio__mark" :class="{ 'small': isChildren }"></span>-->
            <span class="radio__mark"></span>
        </span>
        <span class="radio__title">
            {{ agreement.text }}
        </span>
    </label>
</div>
</template>
<style lang="scss">
.radio {
    margin-right: 16px;
    &--inline {
        display: inline-flex;

        &:first-child {
            margin-left: -36px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.radio__wrapper {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    line-height: 1.3em;
}
.radio__wrapper.center {
    align-items: center;
}

.radio__element {
    position: relative;
    display: flex;

}

.radio__input {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    background: transparent;

    &:focus + .radio__mark, &:active + .radio__mark {
        box-shadow: 0 1px 5px 0 rgba(24, 164, 210, 0.37);
    }

    &:checked + .radio__mark {
        border-color: #18a4d2;

        &::before {
            opacity: 1;
        }
    }
}

.radio__mark {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px #bfcbd9;
    position: relative;
    transition: .4s;
    background: #FFF;

    &::before {
        content: '';
        display: block;
        width: 5px;
        height: 11px;
        border: solid #18a4d2;
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
        left: 8px;
        top: 3px;
        position: absolute;
        opacity: 0;
        transition: .4s;
    }
}

.radio__mark.small {
    margin: 4px 0 0 6px;
    width: 18px;
    height: 18px;

    &::before {
        width: 3px;
        height: 6px;
        border-width: 0 3px 3px 0;
        left: 6px;
        top: 3.5px;
    }
}


.radio__title {
    margin-left: 10px;
    line-height: 1.5;
    padding-top: 2px;
    font-size: 14px;

    & > span, .link {
        display: inline-block;
        font-size: 14px;
    }
}

.radio__error {
    padding-bottom: 10px;
    font-size: 14px;
    color: #e64e73;
}


</style>
