declare global {
    interface Window {
        juice: any;
        jslabApi: any;
    }
}

declare global {
    interface Window { juicyLabConfig: any; }
}

window.juicyLabConfig = {
    apiKey: config.JUICY_SCORE_API_KEY
};

import application from '@/app/store/modules/application';
import RPC from '@/shared/api/RPC'

class JuicyScore {
    static singleton = null as any
    sessionId: any;

    constructor() {
        if (JuicyScore.singleton)
            return JuicyScore.singleton

        window.addEventListener('sessionready', (e: any) => {
            if (!e.detail)
                return

            this.sessionId = e.detail.sessionId
        })

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://score.juicyscore.com/static/js.js';
        const x = document.getElementsByTagName('head')[0];
        x.appendChild(s);

        JuicyScore.singleton = this;
    }

    complete() {
        if (window.juice)
            window.juice.complete()
    }

    sendJuicyId() {
        if (!this.sessionId)
            return

        RPC({
            method: 'sendJuicySession',
            params: {
                session_id: this.sessionId,
                loan_application_id: application.state.loan_application && application.state.loan_application.id
            }
        }).then(() => {
            window.juice = {
                complete: () => {
                    if (window.jslabApi && window.jslabApi.manuallyComplete)
                        window.jslabApi.manuallyComplete.dispatchEvent(new Event('click'))
                }
            }
        })
    }
}

export default JuicyScore
