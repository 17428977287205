import { agreementsModel } from './agreementsModel';
import RPC from "@/shared/api/RPC";
import application from '@/app/store/modules/application';

const customFilterAgreement = (agreements) => {
    return agreements.reduce((acc, agreement) => {
        const stateApplication = application.state as any;

        if (stateApplication?.loan_application?.type === 'repeater' && agreement.type === 'checkbox'&& agreement.code === 'insurance_work_v2') {
            return acc;
        }

        if (agreement.type === 'checkbox' && agreement.code === 'agreement') {
            return acc;
        }

        if (agreement.type === 'text' && agreement.code === 'agreement_docs') {
            return acc;
        }

        if (agreement.type === 'text' && agreement.code === 'insurance_inversion') {
            return acc;
        }

        if (agreement.type === 'checkbox' && agreement.code === 'insurance') {
            return acc;
        }

        if (agreement.type === 'checkbox' && agreement.code === 'insurance_med') {
            return acc;
        }

        if (agreement?.children?.length > 0) {
            const buildedChildren = customFilterAgreement(agreement?.children);
            return [
                ...acc,
                {
                    ...agreement,
                    children: buildedChildren
                }
            ];
        }

        return [...acc, agreement];
    }, []);

};

const customFilterAgreementChecked = (agreement) => {
    if (agreement.type === 'text' && ['insurance_work_v2', 'insurance_work_request'].includes(agreement.code)) {
        return agreementsModel.state.agreementsMap['insurance_work_v2'] === false;
    }

    if (agreement.type === 'text' && ['insurance_request', 'insurance'].includes(agreement.code)) {
        return agreementsModel.state.agreementsMap['insurance'] === false;
    }

    if (agreement.type === 'text' && ['insurance_med', 'insurance_med_request'].includes(agreement.code)) {
        return agreementsModel.state.agreementsMap['insurance_med'] === false;
    }
};

const customEventAgreement = ({ code, value }) => {
    switch (code) {
        case 'insurance_med':
        case 'assignment_of_claims':
        case 'insurance':
        case 'insurance_work_v2': {
            RPC({
                method: 'updateAdditionalAgreement',
                params: {
                    code,
                    agreement: value,
                    loan_application_id: application.state.loan_application?.id
                }
            })
            return;
        }
        default:
    }
};

export {
    customFilterAgreement,
    customEventAgreement,
    customFilterAgreementChecked
}
