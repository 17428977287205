import Vue from 'vue'
import { AgreementsHelper } from './agreementsHelper';

const agreementsModel = {
    state: {
        agreementsPopup: [],
        agreements: [],
        agreementsMap: {},
        requiredAgreements: {},
        isValidateAgreements: false,
        popupSettings: {
            code: '',
            agreementCode: ''
        },
        listSettings: {
            activeLists: {}
        },
        additionalDocumentParams: {}
    },
    mutations: {
        set(state: any, values: any) {
            const set = (state: any, values: any) => {
                Object.keys(values).forEach(key => {
                    const value = values[key]
                    const type = typeof value

                    if (type === 'object' && value !== null && !Array.isArray(value)) {
                        if (!state[key] || Object.keys(value).length === 0) {
                            Vue.set(state, key, {})
                        }

                        return set(state[key], values[key])
                    }

                    if (state[key] === undefined) {
                        return Vue.set(state, key, values[key]);
                    }


                    state[key] = value;
                })
            }
            set(state, values)
        }
    },
    actions: {
        buildRequiredAgreements({ commit }, agreements) {
            commit('set', {
                requiredAgreements: AgreementsHelper.buildRequiredAgreements(agreements)
            });
        },

        buildAgreementsMap({ commit }, agreements) {
            commit('set', {
                agreementsMap: AgreementsHelper.buildAgreementsMap(agreements)
            });
        },

        updateAgreementsMap({ commit, dispatch }, agreement) {
            commit('set', {
                agreementsMap: agreement
            });
            dispatch('validateAgreements');
        },

        validateAgreements({ commit, state }) {
            commit('set', {
                isValidateAgreements: AgreementsHelper.validateAgreements(state.agreementsMap, state.requiredAgreements)
            });
        },

        updatePopupSettings({ commit }, popupSettings = {}) {
            commit('set', {
                popupSettings
            });
        },
        updateListSettings({ commit }, listSettings = {}) {
            commit('set', {
                listSettings
            });
        },
        buildAgreements({ dispatch, state }) {
            dispatch('buildAgreementsMap', [ ...state.agreements, ...state.agreementsPopup ]);
            dispatch('buildRequiredAgreements', [ ...state.agreements, ...state.agreementsPopup ]);
        },
        updateAgreements({ commit, dispatch }, agreements) {
            commit('set', agreements);
            dispatch('buildAgreements');
            dispatch('validateAgreements');
        },
        clearState({ commit }) {
            commit('set', {
                agreementsPopup: [],
                agreements: [],
                agreementsMap: {},
                requiredAgreements: {},
                isValidateAgreements: false,
                popupSettings: {
                    code: '',
                    agreementCode: ''
                },
                listSettings: {
                    activeLists: {}
                }
            });
        }
    },
    getters: {
        popupSettings(state) {
            return state.popupSettings;
        },
        listSettings(state) {
            return state.listSettings;
        },
        agreementsMap(state) {
            return state.agreementsMap;
        },
        apiAgreements(state) {
            return Object.entries(state.agreementsMap).map(([code, value]) => {
                return {
                    code,
                    value
                }
            });
        },
        isValidateAgreements(state) {
            return state.isValidateAgreements;
        },
        agreements(state) {
            return state.agreements;
        },
        agreementsPopup(state) {
            return state.agreementsPopup;
        },
        additionalDocumentParams(state) {
            return state.additionalDocumentParams;
        },
        agreementsPopup2(state) {
            const foundedAgreements = state.agreements.find((agreement) => {
                return agreement?.code === state.popupSettings.agreementCode;
            });

            return foundedAgreements?.children ?? [];
        }
    },
    namespaced: true
};

export {
    agreementsModel
};
