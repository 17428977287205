export const customTypeClassMap = {
    default: 'link--default',
    unobserved: 'link--unobserved',
    withIcon: 'link--with-icon'
};

export const mapTooltip = {
    'insurance_popup': 'InsuranceTooltip',
    'insurance_work_popup': 'WorkTooltip'
};

export const mapAgreementTypes = {
    'parentCheckbox': 'CheckboxParent',
    'checkbox': 'Checkbox',
    'text': 'Title',
    'radio': 'Radio',
};
