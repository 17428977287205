<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        agreement: {
            type: Object,
            default: () => ({})
        },
        isChildren: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<template>
    <div class="title" :class="{ 'title--children': isChildren }">
        <div class="title__item">
            <slot name="links"></slot>
        </div>
        <div class="title__children">
            <slot name="children"></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .title {
        width: 100%;

        &--children {
            .title__item {
                margin-left: 0;
            }
        }

        &__item {
            margin-left: 36px;
        }

        &__children {
            margin-left: 36px;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
