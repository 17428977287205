declare global {
    interface Window {
        ym: any;
        gtag: any;
        dataLayer: any;
    }
}

export default (goal: string) => {
    if (config.GTM_ENABLE == 0) {
        console.log('ym goal', goal)
    } else {
        if (window.ym)
            window.ym(25109435, 'reachGoal', goal)

        if (window.gtag)
            window.gtag('event', goal)
    }
    return true
}

export const sendUserParamsYM = (params: object) => {
    if (config.GTM_ENABLE == 0) {
        console.log('ym', params)
    } else {
        if (window.ym)
            window.ym(25109435, 'userParams', params)
    }
    return true
}

export const send = (attrs: object) => {
    if (config.GTM_ENABLE == 0) {
        console.log('gtag goal', attrs)
    } else {
        if (window.gtag)
            window.gtag('event', 'input_full', attrs)
    }
    return true
}

export const dataLayer = (metrics: any) => {
    if (process.env.NODE_ENV === 'production' && config.GTM_ENABLE == 1) {
        window.dataLayer.push(metrics)
    } else {
        console.log('dataLayer: ', metrics)
    }
}
