<template>
<Popup>
    <div class="container container--big">
        <div class="content__title">
            Соглашения
        </div>
        <div v-if="isLoading" class="loader mar-bot">
            <div class="loader__icon banter-loader">
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
            </div>
            <div class="loader__text">
                Загружаем контент...
            </div>
        </div>
        <AgreementList v-else class="mar-bot" :agreements="agreements" />
        <div class="actions">
            <button class="button"
                    @click="closeWithoutConfirm()">
                Вернуться к займу
            </button>
        </div>
    </div>
</Popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Popup from './Popup.vue'
import AgreementList from '../AgreementList.vue';
import { getAgreementsConfig } from '../../api';

export default defineComponent({
    components: {
        AgreementList,
        Popup
    },
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        agreements() {
            return this.$store.getters['agreementsModel/agreementsPopup'];
        },
    },
    async created() {
        if (this.$store.getters['agreementsModel/agreementsPopup'].length > 0) {
            return;
        }

        this.isLoading = true;
        const agreements = await getAgreementsConfig({
            popup: this.$store.getters['agreementsModel/popupSettings'].code
        });
        this.$store.dispatch('agreementsModel/updateAgreements', {
            agreementsPopup: agreements
        });
        this.isLoading = false;
    },
    methods: {
        closeWithoutConfirm() {
            this.$store.dispatch('agreementsModel/updatePopupSettings', {
                code: ''
            });
        },
    }
})
</script>

<style scoped lang="scss">
.content__link {
    color: inherit;
    border-color: inherit;
}

.mar-bot {
    margin-bottom: 40px;
}

.disabled {
    opacity: 0.3;
    cursor: initial;
}

.container {
    &--big {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
<style lang="scss" scoped>
::v-deep .agreement {
    margin-bottom: 13px;
}
::v-deep .agreement.title {
    max-width: 680px;

    .title__item {
        margin-left: 0;
    }
}
</style>
