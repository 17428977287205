export const price = (v: string | number, showRemain = false): string => {
    const priceArr = String(v).split('.')

    const val = +priceArr[0]

    if (!priceArr[1] && showRemain)
        priceArr[1] = '0'

    if (isNaN(val))
        return String(v)

    const arr = String(val).split('')
        .reverse().reduce((acc, cur: any, index: any) => {
            if (cur !== ' ') {
                if (index !== 0 && index % 3 === 0) {
                    acc.push(' ');
                }

                acc.push(cur);
            }

            return acc;
        }, [] as any);

    let result = arr.reverse().join('')

    if (priceArr[1]) {
        let remain = String(priceArr[1]) as any

        if (remain.length < 2) {
            remain += '0'
        } else if (remain.length > 2) {
            remain = remain.slice(0, 2)
        }

        result = `${result}.${remain}`
    }

    return result
}

export const phone = (v: string): string => {
    return '+7' + v.replace(/[^\d;]/g, '').slice(1)
}

export const timestampDate = (v: string): string => {
    const vToNum = +v

    if (isNaN(vToNum))
        return v

    const date = new Date(vToNum)


    let formatDate = ('0' + date.getDate()).slice(-2) + '.'
        formatDate += ('0' + (date.getMonth() + 1)).slice(-2) + '.'
        formatDate += date.getFullYear()

    return formatDate
}
