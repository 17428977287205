<script lang="ts">
import { defineComponent } from 'vue';
import Checkbox from '@/features/common/checkbox';

export default defineComponent({
    components: { Checkbox },
    props: {
        agreement: {
            type: Object,
            default: () => ({})
        },
        isChildren: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        checked() {
            return this.$store.getters['agreementsModel/agreementsMap'][this.agreement.code] ?? false;
        }
    },
    methods: {
        updateAgreementsMap(checked) {
            this.$store.dispatch('agreementsModel/updateAgreementsMap', {
                [this.agreement.code]: checked
            });
            this.$emit('change', {
                code: this.agreement.code,
                value: checked
            });
        }
    }
});
</script>

<template>
    <div class="checkbox">
        <Checkbox
            class="checkbox__item"
            :class="{ 'checkbox__item--invert': agreement?.inversion }"
            :model="checked"
            @change="updateAgreementsMap"
            :size="isChildren ? 'small' : 'normal'">
            <slot name="links"></slot>
        </Checkbox>
        <div class="checkbox__children">
            <slot name="children"></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .checkbox {
        width: 100%;

        &__item {
            &--invert {
                ::v-deep(.checkbox__input + .checkbox__mark::before) {
                    opacity: 1;
                }

                ::v-deep(.checkbox__input:checked + .checkbox__mark::before) {
                    opacity: 0;
                }
            }
        }

        &__children {
            margin-left: 36px;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
