const initialState = {
    flat: null,
    privateHouse: false,
    textAddress: '',
    addressProps: {}
}

function clear(state: any) {
    const  { flat, privateHouse, textAddress, addressProps } = initialState
    state.flat = flat
    state.privateHouse = privateHouse
    state.textAddress = textAddress
    state.addressProps = addressProps
}

export default {
    state: {
        type: '',
        ...initialState
    } as any,
    mutations: {
        setType(state: any, type: string) {
            if (state.type !== type) {
                clear(state)
                state.type = type
            } 
        },
        setAddress(state: any, addressProps: any) {
            const  { type, flat, privateHouse, textAddress } = addressProps
            state.type = type || ''
            state.flat = flat || initialState.flat
            state.privateHouse = privateHouse || initialState.privateHouse
            state.textAddress = textAddress || initialState.textAddress
            state.addressProps = addressProps || initialState.addressProps
        }
    },
    namespaced: true
}