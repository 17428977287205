import Vue from 'vue'

export default {
    state: {} as any,
    mutations: {
        set(state: any, values: any) {
            const set = (state: any, values: any) => {
                Object.keys(values).forEach(key => {
                    const value = values[key]
                    const type = typeof value

                    if (value === null)
                        return

                    if (type === 'object' && !Array.isArray(value)) {
                        Vue.set(state, key, {})
                        return set(state[key], values[key])
                    }

                    if (typeof state[key] === 'undefined')
                        return Vue.set(state, key, values[key])

                    state[key] = values[key]
                })
            }

            set(state, values)
        }
    },
    namespaced: true
}
