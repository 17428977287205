import api from '@/shared/api/axiosFactory';
import { v4 as uuidv4 } from 'uuid';
import logout from '@/shared/api/logout'
import store from '@/app/store'
import getSiteUrl from "@/shared/helpers/getSiteUrl";
import Cookies from "js-cookie";
import getStatus from "@/shared/api/getStatus";

const domainArr = document.domain.split('.');
domainArr.shift();
const cookiesDomain = '.' + domainArr.join('.');

const request = (data: any, isSignal = false): Promise<any> => {
    return new Promise((resolve, reject) => {
        const requestData = {
            jsonrpc: '2.0',
            id: uuidv4(),
            ...data
        }

        const headers = {} as any;

        if (![
            'getStatus',
            'getFieldOfActivity',
            'validateAddIncome',
            'authByPhone',
            'authCheckCode',
            'authResendCode',
            'getRates',
            'refreshToken',
        ].includes(requestData.method)) {
            headers.csrf = Cookies.get('csrf')
        }

        if (Cookies.get('token')) {
            headers.Authorization = 'Bearer ' + Cookies.get('token');
        }

        if (data.method === 'logout') {
            requestData.params.refreshToken = Cookies.get('refreshToken')

            Cookies.remove('token', { domain: cookiesDomain, path: '/' });
            Cookies.remove('refreshToken', { domain: cookiesDomain, path: '/' });
        }

        const options = {};

        if (isSignal) {
            const abortController = new AbortController();
            store.commit('progress/setAbortController', abortController);
            options['signal'] = abortController.signal;
        }

        api({
            url: '/rpc/v1',
            method: 'post',
            data: requestData,
            headers,
            ...options
        }).then(async (response: any) => {
            const { headers } = response;

            if (headers.token) {
                Cookies.set('token', headers.token, { domain: cookiesDomain });
            }
            if (headers.refreshtoken) {
                Cookies.set('refreshToken', headers.refreshtoken, { domain: cookiesDomain });
            }
            if (requestData.method === 'getStatus' && headers?.csrf) {
                Cookies.set('csrf', headers.csrf, { domain: cookiesDomain });
            }

            if (response.data.error && response.data.error.message) {
                let show = response.data.error.code !== 403;

                const { code } = response.data.error

                if (code) {
                    switch (code) {
                        case -32098: {
                            window.open(getSiteUrl('profile'), '_self')
                            show = false
                            break;
                        }
                        case -32097: {
                            store.commit('popup/open', { name: 'WrongStep' })
                            show = false
                            break;
                        }
                        case -32004: {
                            const response = await getStatus();

                            if (response.data.csrf) {
                                Cookies.set('csrf', response.data.csrf, { domain: cookiesDomain });
                            }

                            resolve(request(data));

                            break;
                        }
                        case -32003:
                        case -32005: {
                            show = false
                            reject({
                                code: response.data.error.code,
                                message: response.data.error.message,
                                data: response.data.error.data,
                                show
                            });
                            break;
                        }
                        case -32006: {
                            if (Cookies.get('refreshToken')) {
                                const result = await request({
                                    method: 'refreshToken',
                                    params: {
                                        refreshToken: Cookies.get('refreshToken'),
                                    }
                                })

                                if (result.success) {
                                    resolve(request(data));

                                    return;
                                }
                            } else {
                                Cookies.remove('token', { domain: cookiesDomain, path: '/' });
                                Cookies.remove('refreshToken', { domain: cookiesDomain, path: '/' });
                            }
                            break;
                        }
                        case -32008: {
                            Cookies.remove('token', { domain: cookiesDomain, path: '/' });
                            Cookies.remove('refreshToken', { domain: cookiesDomain, path: '/' });

                            // Это необходимость, иначе куки не успевают удалиться
                            setTimeout(() => {
                                window.open(getSiteUrl(), '_self')
                            }, 100);

                            break;
                        }
                    }
                }

                reject({
                    code: response.data.error.code,
                    message: response.data.error.message,
                    show
                });
            }

            if (response.data.result && response.data.result.validation_errors && response.data.result.validation_errors[''])
                await logout()

            resolve(response.data?.result ?? response.data)
        }).catch((error) => {
            if (error?.name === 'CanceledError') {
                return;
            }
            reject({
                code: 0,
                message: 'Отсутствует подключение к интернету',
                show: true
            });
        });
    });
}

export default request;
