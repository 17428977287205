<template>
    <div class="content">
        <span class="content__close"
              @click="close()"></span>
        <div v-if="!loaded"
             class="container">
            <div class="loader">
                <div class="loader__icon banter-loader">
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                    <div class="banter-loader__box"></div>
                </div>
                <div class="loader__text">
                    Загружаем контент...
                </div>
            </div>
        </div>
        <slot/>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        name: 'Content',
        data: () => ({
            isPopup: true
        }),
        computed: {
            loaded: {
                get(): boolean {
                    return this.$store.state.popup.loaded
                }
            }
        },
        methods: {
            close() {
                this.$store.commit('popup/close')
            }
        }
    })
</script>

<style lang="scss">
    @import "../style/content";
</style>
