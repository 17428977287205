<script lang="ts">
import { defineComponent } from 'vue';
import { downloadDocuments } from '../../api';
import { customTypeClassMap } from '../../model';

export default defineComponent({
    props: {
        link: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            customTypeClassMap
        }
    },
    methods: {
        async getDocument(link: any) {
            downloadDocuments({
                url: link.url,
                format: link.format,
                name: link.name,
                params: this.$store.getters['agreementsModel/additionalDocumentParams']
            });
        },
    }
})
</script>

<template>
    <span class="agreement-link" :class="customTypeClassMap[link?.customType]">
        <span class="agreement-link__link link" @click.prevent="getDocument(link)">{{ link.linkName }}</span>
    </span>

</template>

<style scoped lang="scss">
.link--default {

}

.link--unobserved {
    .link {
        color: #73869d;
    }
}

.link--with-icon {
    &::before {
        content: '';
        display: block;
        min-width: 24px;
        max-width: 24px;
        height: 28px;
        background-image: url('~@/shared/assets/pdf.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 14px auto auto;
    }

    position: relative;
    cursor: pointer;
    line-height: 1.6;
    padding-bottom: 2px;
    transition: .4s;
    display: inline-block;
    padding-left: 38px;

    .link {
        display: inline;
        text-decoration: none;
        border-bottom: 1px solid rgba(45,58,74,.4);
        color: #000000;

        &:hover, &:focus, &:active {
            border-color: transparent;
            color: #0cbffa;
        }
    }
}
.agreement-link {
    font-size: 14px;
    line-height: 1.5;

    &__link {
        border: none;
        text-decoration: underline;
        display: inline;
    }
}
</style>
