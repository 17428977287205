import Cookies from "js-cookie";
import logger from '@/shared/api/logger';

export default () => {
    let vetrinaUrl = `https://i.helpzaim.ru?uid=${Cookies.get('borrowerUuid')}`

    const partner = Cookies.get('partner');
    const wmid = Cookies.get('wmid');

    if (partner) {
        vetrinaUrl+=`&source=${partner}`;
    }

    if (wmid) {
        vetrinaUrl+=`&wmid=${wmid}`;
    }

    window.open(vetrinaUrl, '_self');

    logger('info', 'Переход на отказную витрину', { uuid: Cookies.get('borrowerUuid') });
}

