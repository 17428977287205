import RPC from '@/shared/api/RPC'
import Cookies from 'js-cookie'
import isMobileApp from '@/shared/helpers/isMobileApp'
import downloadDocuments from './downloadDocuments';

const getAgreementsConfig =  (params = {} as any) => {
    const data = {
        method: 'getAgreementsConfig',
        params: {} as any
    }

    if (Cookies.get('queryAPI'))
        data.params.api = 1

    data.params.source = isMobileApp() ? "mobile" : "browser"

    if (Object.keys(params).length > 0)
        data.params = { ...data.params, ...params }

    return RPC(data)
};

export { getAgreementsConfig, downloadDocuments };
