import RPC from './RPC'

import application from '@/app/store/modules/application'
import Cookies from 'js-cookie'
import getSiteUrl from "@/shared/helpers/getSiteUrl";

const logout = () => new Promise<void>((resolve, reject) => {
    RPC({
        method: 'logout',
        params: {
            // eslint-disable-next-line
            loan_application_id: application.state.id
        }
    }).then(() => {
        try {
            localStorage.clear()
            Cookies.set('csrf', '')
            Cookies.set('token', '')
            Cookies.set('refreshToken', '')
        } catch (e) {
            console.warn(e)
        }

        Object.keys(Cookies.get()).forEach((cookieName) => {
            Cookies.remove(cookieName)
        })
        window.open(getSiteUrl(), '_self')
        resolve()
    }).catch((e) => {
        reject(e)
    })
})

export default logout
