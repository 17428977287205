<template>
    <footer class="footer">
        <div class="container container--big">
            <p class="footer__logo">
                <img src="../../../shared/assets/logo-gray.png" alt="Zaymigo"/>
            </p>
          <div class="footer__description">
            <div v-if="showAdditionalConditions" class="additional-conditions">
              Уведомляем о том, что Вы:
              <div class="option">
                вправе отказаться от доп.услуг в течение 14 календарных дней со дня выражения согласия на их оказание посредством обращения к ООО «Займиго МФК» с заявлением;
              </div>
              <div class="option">
                вправе требовать от ООО «Займиго МФК» возврата денежных средств, уплаченных за услуги, за вычетом стоимости части услуг, фактически оказанных до дня получения ООО «Займиго МФК» заявления;
              </div>
              <div class="option" style="margin-bottom: 24px;">
                вправе требовать от ООО «Займиго МФК» возврата денежных средств, уплаченных третьему лицу за оказание услуг, за вычетом стоимости части услуг, фактически оказанных до дня получения третьим лицом заявления об отказе от услуг, при неисполнении третьим лицом обязанности по возврату денежных средств.
              </div>
              Оплата за предоставленные дополнительные услуги осуществляется за счет собственных
              средств заемщика.
            </div>
          </div>
            <p class="footer__description">
                ООО "Займиго МФК". Регистрационный номер записи в государственном реестре микрофинансовых организаций 651303322004222. ОГРН 1135260005363, присвоен 07.11.2013г. Адрес: 603093, г. Нижний Новгород, ул. Ковровская 21А, помещение 603. Оставляя свои контактные данные на сайте zaymigo.ru, пользователь дает свое согласие на получения информации от компании ООО "Займиго МФК" и партнеров компании.
            </p>
        </div>
    </footer>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'FooterZaym',
      computed:{
        showAdditionalConditions(){
          return this.$store.state.application.showAdditionalConditions
        },
      }
    })
</script>

<style lang="scss">
    @import "../style/footer";
</style>
