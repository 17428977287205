<template>
    <div class="loader">
        <div class="loader__icon banter-loader">
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
        </div>

        <p class="loader__text">
            {{ text }}
        </p>
        <transition name="slide">
            <div v-if="slow">
                <p class="loader__description">
                    Медленное интернет-соединение, пожалуйста, подождите
                </p>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        name: 'Loading',
        props: {
            text: {
                type: String,
                default: 'Загружаем информацию...'
            },
            checkSlow: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                slow: false
            }
        },
        created() {
            if (this.checkSlow)
                this.startTimer()
        },
        methods: {
            startTimer() {
                setTimeout(() => {
                    this.slow = true
                }, 6000)
            }
        }
    })
</script>
