<script lang="ts">
import { defineComponent } from 'vue';
import { Tooltip } from '../tooltips';
import { customTypeClassMap, mapTooltip } from '../../model';

export default defineComponent({
    components: {
        Tooltip
    },
    props: {
        link: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isShowTooltip: false,
            mapTooltip,
            customTypeClassMap
        }
    },
    methods: {
        showTooltip() {
            this.isShowTooltip = true;
        },
        hideTooltip() {
            this.isShowTooltip = false;
        },
    }
})
</script>

<template>
    <span class="link--wrapper" @mouseover="showTooltip" @mouseout="hideTooltip">
        <span class="agreement-link" :class="customTypeClassMap[link?.customType]">
            <span class="agreement-link__link link">
                {{ link.linkName }}
            </span>
        </span>
        <Tooltip v-show="isShowTooltip" :content="link.content"/>
    </span>
</template>

<style scoped lang="scss">
    .link--wrapper {
        position: relative;
    }

    .link--default {

    }

    .link--unobserved {
        .link {
            color: #73869d;
        }
    }

    .agreement-link {
        font-size: 14px;
        line-height: 1.5;

        &__link {
            border: none;
            text-decoration: underline;
            display: inline;
        }
    }
</style>
