import application from './application';
import { agreementsModel } from '@/features/application-form/agreements';

import RPC from '@/shared/api/RPC'

export default {
    state: {
        additional_services: [],
        formula: null,
        amount: null,
        formulaNoDiscount: null,
    },
    mutations: {
        setAdditionalServices(state, services: any[]) {
            const agreementsMap = agreementsModel.getters.agreementsMap;

            services.forEach((service: any) => {
                service.agreement = agreementsMap[service.code];
            })

            state.additional_services = services;
        },
        setFormula(state, formula) {
            state.formula = formula
        },
        setNoDiscountFormula(state, formula) {
            state.formulaNoDiscount = formula
        },
        clear(state) {
            state.additional_services = []
        },
        setNewAmount(state, amount) {
            state.amount = amount
        }
    },
    actions: {
        getFormula({ state, commit }) {
            if (state.formula)
                return

            const loanApplicationRates = {
                amount: application.state.loan_application?.amount,
                term: application.state.loan_application?.term,
            };

            const defaultRates = {
                term: application.state.rates?.term?.default,
                amount: application.state.rates?.amount?.default
            };

            RPC({
                method: 'getReturnAmount',
                params: {
                    amount: loanApplicationRates.amount ?? defaultRates.amount,
                    term: loanApplicationRates.term ?? defaultRates.term
                }
            }).then((response) => {
                commit('setFormula', response.formula)
            })
        }
    },
    namespaced: true
}
