<template>
    <Popup width="300px" height="auto">
        <div class="container container--big">
            <div class="actions">
                <button class="popup__back"
                        @click="closeWithoutConfirm()">
                    Вернуться к оформлению
                </button>
            </div>
            <div class="content__title">
                <Agreement :agreement="firstAgreement"/>
            </div>
            <AgreementList class="mar-bot" :agreements="agreements" />
        </div>
    </Popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Popup from './Popup.vue'
import AgreementList from '../AgreementList.vue';
import Agreement from '../Agreement.vue';

export default defineComponent({
    components: {
        AgreementList,
        Agreement,
        Popup
    },
    computed: {
        firstAgreement() {
            return this.allAgreements[0];
        },
        agreements() {
            const agreements = [...this.allAgreements];
            agreements.shift();
            return agreements;
        },
        allAgreements() {
            return this.$store.getters['agreementsModel/agreementsPopup2'];
        },
    },
    methods: {
        closeWithoutConfirm() {
            this.$store.dispatch('agreementsModel/updatePopupSettings', {
                code: '',
                agreementCode: ''
            });
        },
    }
})
</script>

<style scoped lang="scss">
.actions {
    margin-bottom: 20px;
}
.popup__back {
    padding: 0 0 0 30px;
    background-position-x: 0;
    outline: none;
    border: 0;
    font-size: 13px;
}

.content__title {
    margin-bottom: 20px;

    :deep(.title__item span) {
        font-size: 15px !important;
    }
}

.content__link {
    color: inherit;
    border-color: inherit;
}

.mar-bot {
    margin-bottom: 40px;
}

.disabled {
    opacity: 0.3;
    cursor: initial;
}

.container {
    &--big {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
<style lang="scss" scoped>
::v-deep(.popup__content-wrapper) {
    padding: 20px;

    .popup__content-close {
        width: 13px;
        height: 13px;
    }
}

::v-deep .agreement {
    margin-bottom: 13px;
}
::v-deep .agreement.title {
    max-width: 680px;

    .title__item {
        margin-left: 0;
    }
}
</style>
