<script lang="ts">
import { defineComponent } from 'vue'
import {
    Document,
    Redirect,
    List,
    Popup,
    Tooltip
} from './linkTypes';

const mapLinkTypes = {
    document: 'Document',
    list: 'List',
    link: 'Redirect',
    popup: 'Popup',
    tooltip: 'Tooltip'
}

export default defineComponent({
    components: {
        Document,
        Redirect,
        List,
        Popup,
        Tooltip
    },
    props: {
        text: {
            type: String,
            default: ''
        },
        links: {
            type: Array,
            default: () => []
        },
        agreement: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            buildedLinks: [] as Array<any>,
            lines: [] as Array<string>
        }
    },
    created() {
        const { matchedLinks, splittedLines } = this.generateLinksAndLines(this.text);
        this.buildedLinks = this.buildMapLinks(matchedLinks);
        this.lines = splittedLines;
    },
    methods: {
        buildMapLinks(matchedLinks) {
            return matchedLinks.map((matchedLink) => {
                const foundedLink = this.links.find((link: any) => {
                    return link?.code === matchedLink[2];
                });

                return {
                    ...foundedLink ?? {},
                    linkName: matchedLink[1]
                }
            });
        },
        generateLinksAndLines(str: string) {
            // eslint-disable-next-line
            const matchedLinks = [...str.matchAll(/\[([^\[\]]+)\]\(([^\(\)]+)\)/gm)] as Array<any>;
            // eslint-disable-next-line
            const splittedLines = str.split(/\[[^\[\]]+\]\([^\(\)]+\)/g) as Array<string>;

            return {
                matchedLinks,
                splittedLines
            };
        },
        resolveComponentName(type) {
            return mapLinkTypes[type];
        }
    }
})
</script>

<template>
    <span class="links d-flex">
        <span>
             <span class="links__item" v-for="(line, index) in lines" :key="index">
                <span>{{ line }}</span>
                <component
                    v-if="index !== lines.length - 1"
                    :is="resolveComponentName(buildedLinks[index].type)"
                    :link="buildedLinks[index]"
                    :agreement="agreement" />
            </span>
        </span>
    </span>
</template>

<style scoped lang="scss">
    .links {
        &__item {
            font-size: 14px;
            line-height: 1.5;
        }
    }
    .d-flex {
        display: flex;
        flex-direction: column;
    }
</style>
