<script lang="ts">
import { defineComponent } from 'vue';
import Checkbox from '@/features/common/checkbox';

export default defineComponent({
    components: { Checkbox },
    props: {
        agreement: {
            type: Object,
            default: () => ({})
        },
        isChildren: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checked: false
        }
    },
    computed: {
        isChildrenChecked() {
            return this.agreement.children.every((children) => {
                return this.$store.getters['agreementsModel/agreementsMap'][children.code];
            });
        }
    },
    watch: {
        isChildrenChecked: {
            handler(isChildrenChecked) {
                this.checked = isChildrenChecked;
            }
        }
    },
    methods: {
        updateAgreementsMap(checked) {
            this.$emit('change', {
                code: this.agreement.code,
                value: checked
            });
            if (checked) {
                const updateChildrenMap = this.agreement.children.reduce((acc, children) => {
                    return {
                        ...acc,
                        [children.code]: true
                    };
                }, {});

                this.$store.dispatch('agreementsModel/updateAgreementsMap', {
                    ...updateChildrenMap,
                    [this.agreement.code]: checked
                });
                return;
            }

            this.$store.dispatch('agreementsModel/updateAgreementsMap', {
                [this.agreement.code]: checked
            });
        }
    }
});
</script>

<template>
    <div class="checkboxParent">
        <Checkbox class="checkboxParent__item" :model="checked" @change="updateAgreementsMap">
            <slot name="links"></slot>
        </Checkbox>
        <div class="checkboxParent__children">
            <slot name="children"></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .checkboxParent {
        width: 100%;

        &__item {
            font-weight: bold;
        }
        &__children {
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
