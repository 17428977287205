<template>
    <div>
        <div class="popup__title">
            Что-то пошло не так...
        </div>
        <div class="popup__description">
            К сожалению, произошла ошибка. Попробуйте обновить страницу. Если после обновления ошибка не исчезла, пожалуйста, обратитесь в нашу тех. поддержку по номеру <a href="tel:+78007007286">8 800 700-72-86</a>
        </div>
        <div class="popup__action">
            <button class="button" @click="reloadPage()">
                Обновить страницу
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import popup from '@/shared/mixins/popup'

    export default Vue.extend({
        name: 'Error',
        mixins: [popup],
        methods: {
            reloadPage() {
                location.reload()
            }
        }
    })
</script>
