export default (input: string, mask: string) => {
    const digitsString = input.replace(/[^\d]/g, '')

    let value = '',
        shift = 0

    try {
        mask.split('').forEach((maskChar: string, index: number) => {
            const char = digitsString[index - shift] || undefined

            if (char === undefined) {
                if (value === input.substr(0, value.length) && input[index] === maskChar)
                    value += maskChar

                throw 'BreakException'
            }

            if (maskChar !== '#') {
                value += maskChar
                shift++
            } else if (!isNaN(+char)) {
                value += char
            }
        })
    } catch (e) {
        if (e !== 'BreakException')
            throw e
    }

    return value
}
