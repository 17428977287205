<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        content: {
            type: Object,
            default: () => ({})
        }
    }
})
</script>

<template>
<div class="tooltip">
    <div class="title">{{ content.title }}:</div>
    <div
        v-for="(block, i) in content.list" :key="i">
        <div class="sub-title">{{ block.title }}:</div>
        <div v-if="block.text || block.cost">{{ block.text ?? block.cost }}</div>
        <div class="option" v-for="(line, i) in (block.lines ?? [])" :key="i">
            {{ line }}
        </div>
    </div>
</div>
</template>

<style scoped lang="scss">
.tooltip {
    box-sizing: border-box;
    position: absolute;
    bottom: 100%;
    left: 0px;
    width: 320px;
    padding: 20px 40px;
    white-space: normal;
    text-align: left;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    z-index: 4;
    border: 1px solid #dfdfdf9e;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
    .title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 32px;
    }
    .sub-title, .insured-events, .cost {
        font-size: 14px;
        color: #73869D;
    }
    .insured-events, .cost {
        margin-top: 24px;
    }
    .option {
        position: relative;
        padding-left: 24px;
        &::before {
            display: block;
            content: ".";
            position: absolute;
            top: -3px;
            left: 12px;
            font-weight: 700;
        }
    }
}
</style>
