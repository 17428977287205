import Cookies from 'js-cookie';
import axios from 'axios';
import RPC from '@/shared/api/RPC';

const downloadDocument = ({ format, url, name, params = {} }) => {
    const type = format;
    axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        params: params,
        headers: {'Authorization': `Bearer ${Cookies.get('token')}`},
    }).then((response) => {
        if (response.headers['content-type'] === 'application/json') {
            const uint8Msg = new Uint8Array(response?.data);
            const decodedString = String.fromCharCode.apply(null, [...uint8Msg]);
            const data = JSON.parse(decodedString);

            if (data?.error?.code === -32006) {
                RPC({
                    method: 'refreshToken',
                    params: {
                        refreshToken: Cookies.get('refreshToken'),
                    }
                }).then(() => {
                    downloadDocument({ format, url, name, params });
                })
            }
            return;
        }

        if (response.headers['content-type'] === 'application/octet-stream') {
            const blob = new Blob([response?.data], { type: `application/${type}` });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${name}.${type}`;
            link.click();
        }
    });
}

export default downloadDocument;
