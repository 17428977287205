import Vue from 'vue'

export default Vue.extend({
    created() {
        const parent = this.$parent as any

        if (parent && parent.isPopup)
            this.$store.commit('popup/loaded')
    }
})
