<template>
    <div class="popup" :class="{
        'popup--back': back
    }">
        <div class="popup__box">
            <div class="popup__content"
                 :style="{
                    maxWidth: `${width}px`
                 }">
                <div class="popup__content-wrapper">
                    <span class="popup__content-close" @click="close()"></span>
                    <div v-if="!loaded">
                        <div class="loader">
                            <div class="loader__icon banter-loader">
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                                <div class="banter-loader__box"></div>
                            </div>
                            <div class="loader__text">
                                Загружаем контент...
                            </div>
                        </div>
                    </div>
                    <slot/>
                </div>
                <div v-if="loaded && back"
                     @click="close"
                     class="popup__back">
                    {{ back }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        name: 'Popup',
        data: () => ({
            isPopup: true
        }),
        methods: {
            close() {
                this.$store.commit('popup/close')
            }
        },
        computed: {
            width: {
                get(): number {
                    return this.$store.state.popup.width
                }
            },
            back: {
                get(): string {
                    return this.$store.state.popup.back
                }
            },
            loaded: {
                get(): boolean {
                    return this.$store.state.popup.loaded
                }
            }
        }
    })
</script>

<style lang="scss">
    @import "../style/popup";
</style>
