<template>
    <div class="checkbox"
        :class="{'checkbox--error': error}">
        <transition name="slide">
            <div v-if="error" class="checkbox__error">
                {{ error }}
            </div>
        </transition>
        <label class="checkbox__wrapper" :class="{'center': step === 'firstStep'}">
            <span class="checkbox__element">
                <input v-model="value" type="checkbox" class="checkbox__input"/>
                <span class="checkbox__mark" :class="{'small': size === 'small'}"></span>
            </span>
            <span class="checkbox__title">
                <slot/>
            </span>
        </label>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {send} from '@/shared/helpers/metrica'

export default Vue.extend({
    name: 'Checkbox',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        model: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        pageview: {
            type: [Object, String],
            default: ''
        },
        pageviewOff: {
            type: [Object, String],
            default: ''
        },
        size: {
            type: [String],
            required: false,
            default: 'normal'
        },
        step: {
            type: [String],
            required: false,
            default: ''
        }
    },
    created() {
        if (this.pageview) {
            const pageviewWatcher = this.$watch('model', (v) => {
                if (!v) return

                pageviewWatcher()
                send(this.pageview)
            })
        }

        if (this.pageviewOff) {
            const pageviewWatcherOff = this.$watch('model', (v) => {
                if (v) return

                pageviewWatcherOff()
                send(this.pageviewOff)
            })
        }
    },
    computed: {
        value: {
            get(): boolean {
                return this.model
            },
            set(v: boolean) {
                this.$emit('change', v)
            }
        }
    },
})
</script>
