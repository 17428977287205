<template>
    <div>
        <transition name="hidein">
            <div v-show="mobileMenu"
                class="mobile-menu"
                :class="{
                    'mobile-menu--login': phone,
                    'mobile-menu--active': showMenuBar
                }">
                <div class="mobile-menu__bar">
                    <div>
                        <div class="mobile-menu__top">
                            <a class="mobile-menu__logo"
                            :href="mobileMenuLink"></a>
                            <span class="mobile-menu__close-btn"
                                @click="toggleMenu"></span>
                        </div>
                        <div v-if="!showLogout"
                            class="mobile-menu__user-block">
                            Вход в личный кабинет
                        </div>
                        <div>
                            <ul class="mobile-menu__list" v-if="menuList.length > 0">
                                <li v-for="(item, index) in menuList"
                                    :key="index">
                                    <span v-if="item.active"
                                        class="mobile-menu__item mobile-menu__item-active">{{ item.title }}</span>
                                    <router-link v-else-if="item.path"
                                                :class="{
                                                    'mobile-menu__item-inactive': item.hide
                                                }"
                                                :to="item.path"
                                                exact-active-class="mobile-menu__item-active"
                                                class="mobile-menu__item">{{ item.title }}</router-link>
                                    <a v-else
                                    :href="item.link"
                                    class="mobile-menu__item">{{ item.title }}</a>
                                </li>
                                <li v-if="showLogout">
                                    <span @click="logout"
                                        class="mobile-menu__item">
                                        <span class="mobile-menu__item-icon">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.09 12.59L8.5 14L13.5 9L8.5 4L7.09 5.41L9.67 8H0V10H9.67L7.09 12.59ZM16 0H2C0.89 0 0 0.9 0 2V6H2V2H16V16H2V12H0V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z" fill="#73869D"/>
                                            </svg>
                                        </span>
                                        Выйти
                                    </span>
                                </li>
                            </ul>
                            <ul class="mobile-menu__list" v-if="mainMenuList.length > 0">
                                <li v-for="(item, index) in mainMenuList"
                                    :key="index">
                                    <span v-if="item.active"
                                        class="mobile-menu__item mobile-menu__item-active">{{ item.title }}</span>
                                    <router-link v-else-if="item.path"
                                                :to="item.path"
                                                active-class="mobile-menu__item-active"
                                                class="mobile-menu__item">{{ item.title }}</router-link>
                                    <a v-else
                                    :href="item.link"
                                    class="mobile-menu__item">{{ item.title }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mobile-menu__close"
                    @click="toggleMenu"></div>
            </div>
        </transition>
        <header class="header">
            <div class="header__container"
            :class="{'header__container-repeater': isRepeater}">
                <a class="header__logo"
                :href="headerLogoLink"></a>
                <ul v-if="showMenu"
                    class="header__menu">
                    <li v-for="(item, index) in headerMenu"
                        :class="{
                            'header__menu-inactive': item.hide
                        }"
                        :key="index">
                        <span class="header__menu-active"
                            v-if="item.active">
                            {{ item.title }}
                        </span>
                        <a v-else
                        :href="item.link">{{ item.title }}</a>
                    </li>
                </ul>
                <div v-show="isRepeater" class="header__user-info">
                    <a v-if="!showLogout"
                    class="header__phone"
                    href="tel:+78007007286">8 800 700-72-86</a>
                    <p v-else-if="isRepeater && showMenu"
                    class="header__username">
                        <a :href="personalAreaLink">Личный кабинет</a>
                    </p>
                    <button v-if="showLogout"
                            @click="logout"
                            class="header__action"
                            type="button">
                        Выйти
                    </button>
                    <button v-else
                            @click="login"
                            class="header__action"
                            type="button">
                        Войти
                    </button>
                </div>
                <div v-if="showMenu"
                    @click="toggleMenu"
                    class="header__hamburger"></div>
            </div>
        </header>
    </div>
</template>

<script lang="ts">
import application from '@/shared/mixins/application'
import logout from '@/shared/api/logout'
import VMask from '@/shared/helpers/VMask'
import getSiteUrl from "@/shared/helpers/getSiteUrl";

export default application.extend({
    data: () => ({
        mobileMenu: false,
        showMenuBar: false,
        mainMenuList: [{
            title: 'Вопросы-ответы',
            link: getSiteUrl('profile', 'faq'),
        }, {
            title: 'Компания',
            link: getSiteUrl('profile', 'about')
        }, {
            title: 'Блог',
            link: getSiteUrl('profile', 'blog')
        }, {
            title: 'Документы',
            link: getSiteUrl('profile', 'docs')
        }, {
            title: 'Контакты',
            link: getSiteUrl('profile', 'contacts')
        }]
    }),
    computed: {
        showLogout(): boolean {
            return this.application.id && this.application.borrower && this.application.borrower.id
        },
        headerLogoLink(): string {
            return getSiteUrl()
        },
        mobileMenuLink(): string {
            return getSiteUrl()
        },
        personalAreaLink(): string {
            return getSiteUrl('profile')
        },
        showMenu(): boolean {
            if (this.$route.meta && this.$route.meta.showMenu != undefined) return this.isRepeater && this.$route.meta.showMenu

            return this.isRepeater
        },
        phone(): any {
            if (this.application.borrower) {
                return this.application.borrower.phone
            } else {
                return null
            }
        },
        headerMenu(): any {
            return [{
                title: 'Занять',
                active: true
            }, {
                title: 'Вопросы-ответы',
                link: getSiteUrl('', 'faq')
            }, {
                title: 'Компания',
                link: getSiteUrl('', 'about')
            }]
        },
        menuList(): any {
            if (!this.phone) return []

            return [{
                title: 'Активный займ',
                path: '/',
                hide: true
            }, {
                title: 'История',
                link: getSiteUrl('profile', 'history')
            }, {
                title: 'Профиль',
                link: getSiteUrl('profile', 'profile')
            }]
        }
    },
    methods: {
        logout() {
            logout()
        },
        toggleMenu() {
            this.mobileMenu = !this.mobileMenu

            if (this.mobileMenu) {
                document.body.setAttribute('style', 'overflow:hidden')
            } else {
                document.body.setAttribute('style', 'overflow:auto')
            }

            setTimeout(() => {
                this.showMenuBar = !this.showMenuBar
            })
        },
        login() {
            this.$store.commit('popup/open', { name: 'SMSAuthPhone' })
        }
    },
    watch: {
        '$route'() {
            if (this.mobileMenu) this.toggleMenu()
        }
    },
    filters: {
        VMask
    }
})
</script>

<style lang="scss">
    @import "../style/header";
    @import "../style/mobile-menu";
</style>
