<script lang="ts">
import { defineComponent } from 'vue';
import { customTypeClassMap } from '../../model';

export default defineComponent({
    props: {
        link: {
            type: Object,
            default: () => ({})
        },
        agreement: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isShowTooltip: false,
            customTypeClassMap
        }
    },
    created() {
        this.isShowTooltip = this.agreement.isChildrenOpen;
    },
    methods: {
        toggleDropdown() {
            this.isShowTooltip = !this.isShowTooltip;
            this.$store.dispatch('agreementsModel/updateListSettings', {
                activeLists: {
                    [this.agreement.code]: this.isShowTooltip
                }
            })
        }
    }
})
</script>

<template>
    <span class="agreement-link" :class="customTypeClassMap[link?.customType]">
        <span
            class="agreement-link__link link"
            @click.prevent="toggleDropdown">
            {{ link.linkName }}
        </span>
    </span>
</template>

<style scoped lang="scss">
.link--default {

}

.link--unobserved {
    .link {
        color: #73869d;
    }
}

.agreement-link {
    font-size: 14px;
    line-height: 1.5;

    &__link {
        border: none;
        text-decoration: underline;
        display: inline;
    }
}
</style>
