<template>
  <div id="app">
    <transition name="showin">
      <VContent v-if="fullPopup">
        <component :is="popup"/>
      </VContent>
    </transition>
    <Popup v-if="!fullPopup && popup">
      <component :is="popup"/>
    </Popup>
    <MobileApp>
    </MobileApp>
    <div>
      <HeaderZaym v-if="!isOnlyRouterView" />
      <Loading
        v-if="isLoading"
      />
      <router-view
        v-else
      />
    </div>
    <FooterZaym v-if="!isOnlyRouterView" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  import HeaderZaym from '@/widgets/header';
  import FooterZaym from '@/widgets/footer';

  import Popup from '@/features/common/popup';
  import VContent from '@/features/common/content';
  import ErrorWin from '@/features/popup/Error.vue';
  import RPC from "@/shared/api/RPC";
  import Cookies from "js-cookie";
  import Loading from "@/pages/application-form/Loading.vue";

  export default Vue.extend({
    name: 'App',
    data: () => ({
      isLoading: false,
    }),
    computed: {
      popup(): string {
        return this.$store.state.popup.open
      },
      fullPopup(): string {
        return this.$store.state.popup.full
      },
      isOnlyRouterView(): boolean {
        return !!this.$route.meta?.onlyRouterView
      },
    },
    watch: {
      '$route.name'() {
        window.scrollTo(0, 0)
      }
    },
    methods: {
      bodyClickHandler () {
        this.$store.commit('popup/setFocusedField', 'closeDropdown')
      },
    },
    async created() {
      document.addEventListener('click', this.bodyClickHandler)

      this.isLoading = true;

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('loanTerm')) {
        localStorage.setItem('term', urlParams.get('loanTerm')!)
      }

      if (urlParams.get('loanValue')) {
        localStorage.setItem('amount', urlParams.get('loanValue')!)
      }

      if (urlParams.get('code') && urlParams.get('operationToken')) {
        Cookies.remove('needMobileIdAuth');

        try {
          await RPC({
            method: 'getMobileIdResult',
            params: {
              code: urlParams.get('code'),
              operationToken: urlParams.get('operationToken'),
              term: +(urlParams.get('loanTerm') || localStorage.getItem('term') || (this.$store.state as any).application.term),
              amount: +(urlParams.get('loanValue') || localStorage.getItem('amount') || (this.$store.state as any).application?.amount),
              agreements: [
                {
                    code: 'assignment_of_claims',
                    value: false,
                },
                {
                    code: 'consent_obligations_borrower',
                    value: true
                }
              ]
            }
          }).then(async (response) => {
            if (response.showAuthForm) {
              this.$store.commit('popup/open', {
                name: 'SMSAuthPhoneExisted',
                title: 'Такой номер существует',
                customOptions: {
                  phone: response.phone,
                  description: 'Пользователь с таким номером уже существует. Если это ваш номер, нажмите "Войти" и авторизуйтесь по коду из SMS.'
                }
              })
            }
          })
        } catch (e) {
          console.log(e, 'error');
        }
      }

      this.isLoading = false;
    },
    beforeDestroy() {
      document.removeEventListener('click', this.bodyClickHandler)
    },
    components: {
      HeaderZaym,
      FooterZaym,
      Popup,
      VContent,
      MobileApp: () => import(/* webpackPrefetch: true */ '@/features/common/mobile-app/component/MobileApp.vue'),
      Auth: () => import(/* webpackPrefetch: true */ '@/features/popup/Auth.vue'),
      ForgotPassword: () => import(/* webpackPrefetch: true */ '@/features/popup/ForgotPassword.vue'),
      ForgotPasswordCode: () => import(/* webpackPrefetch: true */ '@/features/popup/ForgotPasswordCode.vue'),
      ForgotPasswordNewPassword: () => import(/* webpackPrefetch: true */ '@/features/popup/ForgotPasswordNewPassword.vue'),
      Esia: () => import('@/features/popup/application-form/Esia.vue'),
      Approval: () => import('@/features/popup/approval/component/Approval.vue'),
      Calculator: () => import(/* webpackPrefetch: true */ '@/features/application-form/calculator'),
      ErrorWin,
      RecoveryAccountWin: () => import('@/features/popup/passport-exists/RecoveryAccount.vue'),
      EsiaInfo: () => import('@/features/popup/application-form/EsiaInfo.vue'),
      PromoCode: () => import('@/features/popup/Promocode.vue'),
      AdditionalService: () => import('@/features/popup/additional-service'),
      WrongStep: () => import('@/features/popup/WrongStep.vue'),
      SMSAuthPhoneExisted: () => import('@/features/popup/sms-auth/PhoneExisted.vue'),
      SMSAuthCodeExisted: () => import('@/features/popup/sms-auth/CodeExisted.vue'),
      SMSAuthPhone: () => import('@/features/popup/sms-auth/Phone.vue'),
      SMSAuthCode: () => import('@/features/popup/sms-auth/Code.vue'),
      ChangeTerm: () => import('@/features/popup/ChangeTerm.vue'),
      WarningChangeTerm: () => import('@/features/popup/WarningChangeTerm.vue'),
      Operators: () => import(/* webpackPref: true */ '@/features/popup/application-form/Operators.vue'),
      Loading
    }
  })
</script>


<style lang="scss" src="@/app/style/main.scss"></style>
