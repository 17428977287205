import esia from '@/app/store/modules/esia'

export default {
    state: {
        points: {
            surname: 5,
            name: 5,
            patronymic: 5,
            phone: 5,
            email: 10,
            _agreement: 3,
            passport: {
                // eslint-disable-next-line
                birth_date: 0,
                // eslint-disable-next-line
                birth_place: 0,
                // eslint-disable-next-line
                issue_date: 0,
                issuer: 0,
                // eslint-disable-next-line
                issuer_code: 0,
                series: 0,
                number: 0
            },
            contacts: {
                phone: 35,
                name: 10
            }
        },
        active: {
            surname: false,
            name: false,
            patronymic: false,
            phone: false,
            email: false,
            _agreement: false,
            passport: {
                // eslint-disable-next-line
                birth_date: false,
                // eslint-disable-next-line
                birth_place: false,
                // eslint-disable-next-line
                issue_date: false,
                issuer: false,
                // eslint-disable-next-line
                issuer_code: false,
                series: false,
                number: false
            },
            contacts: {
                phone: false,
                name: false
            }
        },
        requestCount: 0,
        loadingTimerId: 0,
        loading: true,
        firstRequest: true,
        abortController: null
    },
    mutations: {
        set(state: any, activeObject: any) {
            const set = (val: any, obj: any, setKey: string) => {
                if (typeof val === 'object') {
                    Object.keys(val).forEach((key: string) => {
                        set(val[key], val, key)
                    })

                    val = {
                        ...obj[setKey],
                        ...val
                    }
                }

                obj[setKey] = val
            }

            set(activeObject, state, 'active')
        },
        setRequest(state: any, val: boolean) {
            if (val) {
                ++state.requestCount
            } else {
                --state.requestCount < 0 ? state.requestCount = 0 : ''
                state.firstRequest = false
            }
        },
        setLoading(state: any, val: boolean) {
            state.loading = val
            state.loadingTimerId = 0
        },
        setAbortController(state: any, abortController: any) {
            state.abortController = abortController;
        }
    },
    actions: {
        setLoading({ state, commit }, val: boolean) {
            if (val) {
                state.loadingTimerId = setTimeout(() => {
                    commit('setLoading', true)
                }, 400)
            } else {
                clearTimeout(state.loadingTimerId)
                commit('setLoading', false)
            }
        }
    },
    getters: {
        abortController(state) {
            return state.abortController;
        },
        percent(state: any): number {
            const reviewList = (val: any, point: any): number => {
                let sum = 0

                if (typeof val === 'object') {
                    Object.keys(val).forEach((key: string) => {
                        sum += reviewList(val[key], point[key])
                    })
                } else {
                    if (val && !isNaN(point))
                        sum += point
                }

                return sum
            }

            let total = reviewList(state.active, state.points)

            if (esia.state.name && esia.state.surname)
                total += 20

            return total
        },
        request(state: any): boolean {
            return !!state.requestCount || state.loadingTimerId
        }
    },
    namespaced: true
}
