import Vue from 'vue'
import router from '@/app/router'
import store from '@/app/store'

import RPC from '@/shared/api/RPC'

import getStatus from '@/shared/api/getStatus'
import { getUrl } from '@/shared/helpers/statuses'
import redirectToDecline from '@/shared/helpers/redirectToDecline';
import { openForm3ds } from '@/shared/helpers/validator3ds'
import metrica, { send, dataLayer } from '@/shared/helpers/metrica'
import Cookies from 'js-cookie'
import getSiteUrl from "@/shared/helpers/getSiteUrl";
import JuicyScoreClass from "@/shared/helpers/juicy";

export default {
    state: {
        borrower: {},
        amount: 4000,
        term: 20,
        attachCardErrors: {},
        showAdditionalConditions: false
    } as {
        borrower: Record<string, unknown>;
        amount: number;
        term: number;
        attachCardErrors: Record<string, unknown>;
        id: number;
        status: 'transfer_type' | 'prior_approval' | 'declined' | 'waiting_result' | 'approved';
        loan_application: Record<string, unknown>;
        fieldOfActivityList: any[];
        rates: {
            term: {
                default: number;
                min: number;
                max: number;
            };
            amount: {
                default: number;
                min: number;
                max: number;
            };
        };
        // TODO удалить
        showAdditionalConditions: boolean
    },
    mutations: {
        set(state: any, values: any) {
            const set = (state: any, values: any) => {
                Object.keys(values).forEach(key => {
                    const value = values[key]
                    const type = typeof value

                    if (type === 'object' && value !== null && !Array.isArray(value)) {
                        if (!state[key])
                            Vue.set(state, key, {})

                        return set(state[key], values[key])
                    }

                    if (state[key] === undefined)
                        return Vue.set(state, key, values[key])

                    state[key] = values[key]
                })
            }
            set(state, values)
        },
        setAttachCardErrors(state: any, errors: any) {
            Vue.set(state, 'attachCardErrors', errors)
        },
        clear(state: any) {
            Object.keys(state).forEach((key) => {
                delete state[key]
            })
        }
    },
    actions: {
        update({ commit, dispatch, state }) {
            return new Promise<void>((resolve, reject) => {
                commit('progress/setRequest', {
                    name: 'store/application',
                    func: 'update'
                }, { root: true })
                const params = {} as any
                const routeQuery = router.currentRoute.query

                routeQuery && +routeQuery.cpa === 1
                    ? params.cpa = { ...routeQuery }
                    : params.cpa = []

                if (routeQuery.loanValue) {
                    params.loanValue = Number(routeQuery.loanValue)
                }

                getStatus(params).then(async (response: any) => {
                    if (response.data?.loan_application == null) {
                        throw new Error('undefined error');
                    }

                    if (response.data.loan_application.status === 'declined') {
                        const uuid = Cookies.get('borrowerUuid');
                        // new KR-4616
                        dataLayer({
                            'event': 'Reject',
                            'Uuid': uuid
                        });
                        redirectToDecline();
                        resolve();

                        return;
                    }

                    commit('set', response.data);

                    const JuicyScore = new JuicyScoreClass();
                    JuicyScore.sendJuicyId();

                    if (response.data.loan_application.status === 'transfer_type') {
                        if (response.data.loan_application?.front_3ds_status === 'load') {
                            setTimeout(() => dispatch('update'), 500);
                            resolve();

                            return;
                        }

                        if (response.data.loan_application?.front_3ds_status === 'attach_card') {
                            if (state.attachCardParams) {
                                dispatch('attachCard');
                            }

                            resolve();
                        }
                    }

                    if (response.data.loan_application.status === 'waiting_result' && response.data.loan_application?.cards.length) {
                        const sendedMetrics3dsStatus = Boolean(localStorage.getItem('sendedMetrics3dsStatus'))
                        const threeDsStatus = response.data.loan_application.cards[0].three_ds_status

                        if (!sendedMetrics3dsStatus) {
                            dataLayer({'event': state.loan_application.type === 'repeater' ? 'repeat3ds' : 'new3ds', '3dsStatus': threeDsStatus, 'Uuid': Cookies.get('borrowerUuid')})

                            localStorage.setItem('sendedMetrics3dsStatus', threeDsStatus)
                        }
                    } else {
                        localStorage.setItem('sendedMetrics3dsStatus', '')
                    }

                    if (state.loan_application) {
                        dispatch('updateProgress');
                    }

                    if (response.data.loan_application.status !== 'waiting_result') {
                        dispatch('getCalculatorRates');
                    }

                    if (response.data.loan_application.status === 'additional_info') {
                        await dispatch('getFieldOfActivity');
                    }

                    dispatch('checkRoute', response.data.loan_application).then(resolve)
                }).catch((e) => {
                    if (e.code === -32099 || e.code === -32098) {
                        reject(e)
                        return
                    }

                    if (e.code !== -32603)
                        commit('popup/open', { name: 'ErrorWin' }, { root: true })

                    dispatch('getCalculatorRates');
                    dispatch('checkRoute').then(reject)
                    commit('clear')
                }).finally(() => {
                    commit('progress/setRequest', false, { root: true })
                })
            })
        },
        updateProgress({ commit, state }) {
            const {
                borrower: {
                    surname = false,
                    name = false,
                    patronymic = false,
                    phone = false,
                    email = false,
                    contacts
                }
            } = state.loan_application

            const progressObject = {
                surname: !!surname,
                name: !!name,
                patronymic: !!patronymic,
                phone: !!phone,
                email: !!email,
                _agreement: true,
                contacts: {
                    phone: false,
                    name: false
                }
            } as any

            if (progressObject.name && progressObject.surname)
                progressObject.patronymic = true

            if (contacts)
                contacts.forEach((item: any) => {
                    if (item.type !== 'la_friend')
                        return

                    progressObject.contacts.phone = !!item.phone
                    progressObject.contacts.name = !!item.name
                })

            commit('progress/set', progressObject, { root: true })
        },
        checkRoute({ commit }, payload = {} as any) {
            return new Promise<void>((resolve) => {
                if (payload.status === 'approved' && payload?.additional_status == null) {
                    window.open(getSiteUrl('profile'), '_self');
                    return;
                }

                const url = getUrl(payload.status, payload.type, payload?.additional_status);

                if (router.currentRoute.path === url) {
                    resolve();
                    return;
                }

                commit('progress/setLoading', true, { root: true });

                router.replace(url).catch((err) => {
                    console.log('router err', err);
                }).finally(() => {
                    resolve();
                });
            })
        },
        getCalculatorRates({ commit, dispatch }) {
            commit('progress/setLoading', true, { root: true })

            RPC({
                method: 'getRates'
            }).then((response) => {
                commit('set', {
                    rates: response
                })
                dispatch('calculator/getFormula', null, { root: true })
            }).catch((e) => {
                if (e.show !== undefined && e.show)
                    commit('popup/open', { name: 'ErrorWin' }, { root: true })
            }).finally(() => {
                commit('progress/setLoading', false, { root: true })
            })
        },
        getFieldOfActivity({ commit }) {
            return new Promise<void>((resolve, reject) => {
                commit('progress/setLoading', true, { root: true })

                RPC({
                    method: 'getFieldOfActivity'
                }).then((response) => {
                    commit('set', {
                        fieldOfActivityList: response
                    });
                    resolve(response);
                }).catch((e) => {
                    if (e.show !== undefined && e.show)
                        commit('popup/open', { name: 'ErrorWin' }, { root: true });
                    reject(e);
                }).finally(() => {
                    commit('progress/setLoading', false, { root: true });
                });
            });
        },
        attachCard({ dispatch, commit, state }) {
            RPC({
                method: 'attachCard',
                params: state.attachCardParams
            }).then((response: any) => {
                if (response.bankData) {
                    openForm3ds(response.bankData)

                    dataLayer({'event': state.loan_application.type === 'repeater' ? 'repeatForm3ds' : 'newForm3ds', 'Uuid': Cookies.get('borrowerUuid')})

                    return
                }

                if (response.status === 'success') {
                    if (state.loan_application.type === 'repeater') {
                        metrica('repeatstep1')
                        send({'event_category': ' repeat', 'event_label': 'repeat_step1_next'})
                        dataLayer({'event': 'RequestLoan', 'RequestLoanSum': state.amount || 10000, 'RequestLoanTerm': state.term || 12})
                        state.attachCardParams.promoCode && dataLayer({'event': 'PromoCodeDone', 'PromoCodeLocation':'FirstStep', 'PromoCodeNumber': state.attachCardParams.promoCode})
                    } else {
                        metrica('newstep4')
                        send({'event_category': 'form_step_4', 'event_label': 'step4_next'})
                    }

                    dispatch('update')
                } else if (response.status === 'validation_error') {
                    const errors = response.validation_errors

                    commit('setAttachCardErrors', {
                        holder_name: errors['[card][holder_name]'] ? errors['[card][holder_name]'].message : '',
                        date: errors['[card][date]'] ? errors['[card][date]'].message : '',
                        number: errors['[card][number]'] ? errors['[card][number]'].message : ''
                    })
                } else {
                    throw new Error('undefined error')
                }
            }).catch((e) => {
                if (e.show !== undefined && e.show) store.commit('popup/open', { name: 'ErrorWin' })
                store.commit('progress/setLoading', false)
            }).finally(() => store.commit('progress/setLoading', false))
        },
    },
    getters: {
        fieldOfActivityOptions(state) {
            return (state?.fieldOfActivityList ?? []).map(({ code, income, description }) => {
                return {
                    income,
                    title: description,
                    value: code
                }
            })
        }
    },
    namespaced: true
};

