import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/esia/info',
        name: 'esia-info',
        component: () => import(/* webpackChunkName: "esia-info" */ '@/features/popup/application-form/EsiaInfo.vue'),
        meta: {
            onlyRouterView: true,
        }
    },
    {
        path: '/redirect-to-zaymigo',
        name: 'redirectToZaymigo',
        component: () => import(/* webpackChunkName: "esia-code" */ '@/pages/Redirect.vue')
    },
    {
        path: '/view-document',
        name: 'view-document',
        component: () => import(/* webpackChunkName: "main" */ '@/pages/Document.vue')
    },
    {
        path: '/esia/after',
        name: 'esia-code',
        component: () => import(/* webpackChunkName: "esia-code" */ '@/pages/application-form/esia/Code.vue')
    },
    {
        path: '/esia/mobile_after',
        name: 'mobile_after',
        component: () => import(/* webpackChunkName: "esia-code" */ '@/pages/application-form/esia/MobileCode.vue')
    },
    {
        path: '/',
        name: 'Loan',
        component: () => import('@/app/layouts/ApplicationForm.vue'),
        children: [
            {
                path: '/repeater',
                name: 'Repeater',
                meta: {
                    step: 1,
                    showHeader: false
                },
                component: () => import(/* webpackChunkName: "receive" */ '@/pages/repeat-form/Main.vue')
            },
            {
                path: '/',
                name: 'Main',
                meta: {
                    step: 1
                },
                component: () => import(/* webpackChunkName: "main" */ '@/pages/application-form/steps/Main.vue')
            },
            {
                path: '/code',
                name: 'code',
                meta: {
                    showHeader: false,
                    step: 1,
                    backButtonTxt: 'Изменить данные'
                },
                component: () => import(/* webpackChunkName: "code" */ '@/pages/application-form/steps/Code.vue')
            },
            {
                path: '/passport',
                name: 'passport',
                meta: {
                    step: 2
                },
                component: () => import(/* webpackChunkName: "passport" */ '@/pages/application-form/steps/Passport.vue')
            },
            {
                path: '/additional',
                name: 'additional',
                meta: {
                    step: 3
                },
                component: () => import(/* webpackChunkName: "additional" */ '@/pages/application-form/steps/Additional.vue')
            },
            {
                path: '/receive',
                name: 'receive',
                meta: {
                    step: 4
                },
                component: () => import(/* webpackChunkName: "receive" */ '@/pages/application-form/steps/Recive.vue')
            },
            {
                path: '/check',
                name: 'check',
                component: () => import(/* webpackChunkName: "check" */ '@/pages/application-form/Check.vue'),
                meta: {
                    showMenu: false
                }
            },
            {
                path: '/approve',
                name: 'approve',
                component: () => import(/* webpackChunkName: "approve" */ '@/pages/application-form/Approve.vue'),
                meta: {
                    showMenu: false
                }
            },
            {
                path: '/reject',
                name: 'reject',
                component: () => import(/* webpackChunkName: "reject" */ '@/pages/application-form/Decline.vue'),
                meta: {
                    showMenu: false
                }
            },
            {
                path: '/confirmation',
                name: 'confirmation',
                component: () => import(/* webpackChunkName: "confirmation" */ '@/pages/application-form/Confirmation.vue'),
                meta: {
                    showMenu: false
                }
            },
            {
                path: 'update-passport',
                name: 'update-passport',
                component: () => import(/* webpackChunkName: "update-passport" */ '@/pages/repeat-form/CheckPassport.vue')
            },
            {
                path: '/esia-not-identified',
                name: 'esia-not-identified',
                component: () => import(/* webpackChunkName: "esia-not-identified" */ '@/pages/application-form/esia/NotIdentified.vue'),
                meta: {
                    controls: false
                }
            },
            {
                path: '/esia-exist',
                name: 'esia-exist',
                component: () => import(/* webpackChunkName: "esia-exist" */ '@/pages/application-form/esia/Exist.vue'),
                meta: {
                    controls: false
                }
            },
            {
                path: '/upload-passport',
                name: 'upload-passport',
                component: () => import('@/pages/UploadPassport.vue')
            },
            {
                path: '*',
                name: 'not-found',
                component: () => import(/* webpackChunkName: "not-found" */ '@/pages/application-form/NotFound.vue'),
                meta: {
                    controls: false
                }
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
